// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-archive-blog-js": () => import("./../../../src/templates/archiveBlog.js" /* webpackChunkName: "component---src-templates-archive-blog-js" */),
  "component---src-templates-legals-js": () => import("./../../../src/templates/legals.js" /* webpackChunkName: "component---src-templates-legals-js" */),
  "component---src-templates-single-blog-js": () => import("./../../../src/templates/singleBlog.js" /* webpackChunkName: "component---src-templates-single-blog-js" */)
}

